import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { AuthenticationService } from '../authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
  }

  public async canActivate(): Promise<boolean> {
    let canActivate = null;

    if (this.authenticationService.isLoggedIn) {
      canActivate = true;
    } else if (this.authenticationService.isRefreshTokenPresent) {
      this.authenticationService.refreshToken();

      await (async () => {
        while (this.authenticationService.isRefreshingToken) {
          await new Promise(resolve => setTimeout(resolve, 100));
        }

        if (this.authenticationService.isLoggedIn) {
          canActivate = true;
        } else {
          canActivate = false;
        }
      })();
    } else {
      canActivate = false;
    }

    if (!canActivate) {
      await this.router.navigate(['']);
    }

    return canActivate;
  }
}

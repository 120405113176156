import { Injectable } from '@angular/core';

import * as Sentry from '@sentry/angular-ivy';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  public async genericError(error) {
    Sentry.captureException(error);
  }
}

import { Injectable } from '@angular/core';

import info from '../../assets/info.json';

@Injectable({
  providedIn: 'root'
})
export class InfoContentService {
  private infoStrings: Map<string, string> = new Map<string, string>([
    ['home', InfoContentService.home],
    ['rating', InfoContentService.rating],
    ['quiz', InfoContentService.quiz],
    ['listsOverview', InfoContentService.listsOverview]
  ]);

  private static get home() {
    let infoData = '';
    info.home.forEach(value => {
      infoData += value;
    });
    return infoData;
  }

  private static get rating() {
    let infoData = '';
    info.rating.forEach(value => {
      infoData += value;
    });
    return infoData;
  }

  private static get quiz() {
    let infoData = '';
    info.quiz.forEach(value => {
      infoData += value;
    });
    return infoData;
  }

  private static get listsOverview() {
    let infoData = '';
    info.listsOverview.forEach(value => {
      infoData += value;
    });
    return infoData;
  }

  public getInfo(page: string): string {
    return this.infoStrings.get(page) ||
      '<h3>Under construction</h3><p class=\'text-white\'>No help content created for this page yet</p>';
  }
}

import { Injectable } from '@angular/core';

import { Geolocation, PermissionStatus, Position } from '@capacitor/geolocation';
import AutocompletePrediction = google.maps.places.AutocompletePrediction;

declare let google;

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private AutocompleteService: google.maps.places.AutocompleteService;
  private PlacesService: google.maps.places.PlacesService;
  private _latitude: number;
  private _longitude: number;
  private originalLatitude: number;
  private originalLongitude: number;
  private tempLatitude: number;
  private tempLongitude: number;
  public position: any = {};
  public permissionsDenied = false;

  public set latitude(value: number) {
    if (!this.originalLatitude) {
      this.originalLatitude = value;
    }
    this._latitude = value;
  }

  public set longitude(value: number) {
    if (!this.originalLongitude) {
      this.originalLongitude = value;
    }
    this._longitude = value;
  }

  public get originalLatLng(): google.maps.LatLng {
    return new google.maps.LatLng(
      this.originalLatitude,
      this.originalLongitude
    );
  }

  public get preSearchLatLng(): google.maps.LatLng {
    return new google.maps.LatLng(
      this.tempLatitude,
      this.tempLongitude
    );
  }

  public set preSearchLatLng(latLng: google.maps.LatLng) {
    this.tempLatitude = latLng.lat();
    this.tempLongitude = latLng.lng();
  }

  public get latLng(): google.maps.LatLng {
    return new google.maps.LatLng(this._latitude, this._longitude);
  }

  public get autocompleteService(): google.maps.places.AutocompleteService {
    return this.AutocompleteService;
  }

  public get placesService(): google.maps.places.PlacesService {
    return this.PlacesService;
  }

  private static metersToMiles(distance: number): number {
    return distance * 0.000621371192;
  }

  public setPlacesService(element: google.maps.Map | HTMLElement) {
    if (!this.placesService) {
      this.PlacesService = new google.maps.places.PlacesService(element);
    }
  }

  public setAutocompleteService() {
    if (!this.autocompleteService) {
      this.AutocompleteService = new google.maps.places.AutocompleteService();
    }
  }

  public checkPermissions(): Promise<PermissionStatus> {
    if (
      navigator.userAgent.includes('Safari') &&
      !navigator.userAgent.includes('Chrome')
    ) {
      return new Promise((resolve) => {
        resolve({ location: 'prompt', coarseLocation: 'prompt' });
      });
    }
    return Geolocation.checkPermissions();
  }

  public getCurrentPosition(): Promise<string> {
    return new Promise((resolve, reject) => {
      Geolocation.getCurrentPosition().then(
        (currentPosition: Position) => {
          this.position = currentPosition;
          this.latitude = this.position.coords.latitude;
          this.longitude = this.position.coords.longitude;
          this.permissionsDenied = false;
          resolve('success');
        },
        (error) => {
          this.latitude = 39.8283;
          this.longitude = -98.5795;

          if (
            error.message ===
            'Origin does not have permission to use' + ' Geolocation service'
          ) {
            alert(
              'Geolocation services not available due to a permissions' +
                ' issue'
            );
            console.log(
              'This is probably being run on Safari or another' +
                ' browser that doesn\'t have permissions to access the' +
                ' Geolocation plugin.'
            );
          } else if (error.code === 2) {
            alert(
              'There was an error getting your location due to an internal' +
                ' error unrelated to this application. Please either try again' +
                ' later or use a different browser.'
            );
          } else {
            alert(error.message);
          }

          this.permissionsDenied = true;
          console.log(error);
          reject(error);
        }
      );
    });
  }

  public getLocationSearchPredictions(
    locationSearchQuery: string
  ): Promise<Array<AutocompletePrediction>> {
    return new Promise((resolve) => {
      if (locationSearchQuery) {
        const config = {
          types: ['geocode'],
          input: locationSearchQuery,
        };

        this.autocompleteService.getPlacePredictions(
          config,
          (predictions, status) => {
            if (
              status === google.maps.places.PlacesServiceStatus.OK &&
              predictions
            ) {
              const locationSearchPredictions = [];

              predictions.forEach((prediction: AutocompletePrediction) => {
                locationSearchPredictions.push(prediction);
              });

              resolve(locationSearchPredictions);
            }
          }
        );
      } else {
        resolve([]);
      }
    });
  }

  public async getDistanceToRestaurant(location): Promise<number> {
    if (!this.permissionsDenied) {
      await this.getCurrentPosition();
      const currentLatLng = {
        lat: this._latitude,
        lng: this._longitude
      };

      return LocationService.metersToMiles(
        google.maps.geometry.spherical.computeDistanceBetween(
          currentLatLng,
          location
        )
      );
    }
  }
}

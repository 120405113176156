import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouteReuseStrategy, RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { LaunchNavigator } from '@ionic-native/launch-navigator/ngx';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';

import { JwtModule } from '@auth0/angular-jwt';

import * as Sentry from '@sentry/angular-ivy';
import { TraceService } from '@sentry/angular-ivy';
import { CaptureConsole } from '@sentry/integrations';

import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AuthenticationInterceptor } from './services/interceptors/authentication.interceptor';

import { ServiceLocatorService } from './services/service-locator.service';
export function getToken() {
  return localStorage.getItem('token');
}

if (environment.enableSentry) {
  Sentry.init({
    dsn: 'https://99ad7d999eb04ed28ebccaa59d732535@o1052825.ingest.sentry.io/6036726',
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ['localhost', environment.apiUrl],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      new Sentry.Replay(),
      new CaptureConsole({
        levels: ['warn', 'error', 'debug', 'assert']
      }),
    ],
    tracesSampleRate: 0.05,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: environment.name + '@' + environment.platform,
    release: 'course-app-client@' + environment.version,
    dist: environment.build.toString(),
    ignoreErrors: [
      'Non-Error exception captured'
    ]
  });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      rippleEffect: false,
      statusTap: true,
      platform: {
        'desktop': (win) => {
          const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(win.navigator.userAgent);
          return !isMobile;
        }
      }
    }),
    AppRoutingModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: getToken,
        skipWhenExpired: true,
        allowedDomains: environment.allowedAuthenticationDomains,
      },
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    RouterModule
  ],
  providers: [
    InAppBrowser,
    CallNumber,
    LaunchNavigator,
    DatePipe,
    OpenNativeSettings,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        logErrors: true,
        showDialog: false
      }),
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor(
    private injector: Injector,
    public trace: TraceService
  ) {
    ServiceLocatorService.Injector = this.injector;
  }
}

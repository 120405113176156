import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthenticationGuard } from './services/auth-guards/authentication.guard';
import { LandingGuard } from './services/auth-guards/landing.guard';
import { ListsRoutingModule } from './pages/lists/lists-routing.module';
import { RestaurantRoutingModule } from './pages/restaurant/restaurant-routing.module';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/landing/landing.module').then(m => m.LandingPageModule),
    canActivate: [LandingGuard]
  },
  {
    path: 'landing',
    loadChildren: () => import('./pages/landing/landing.module').then(m => m.LandingPageModule),
    canActivate: [LandingGuard]
  },
  {
    path: 'info-modal',
    loadChildren: () => import('./modals/info-modal/info-modal.module').then(m => m.InfoModalPageModule)
  },
  {
    path: 'list-modal',
    loadChildren: () => import('./modals/list-modal/list-modal.module').then(m => m.ListModalModule)
  },
  {
    path: 'menu-modal',
    loadChildren: () => import('./modals/menu-modal/menu-modal.module').then(m => m.MenuModalPageModule)
  },
  {
    path: 'home',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'survey',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./pages/survey/survey.module').then(m => m.SurveyPageModule)
  },
  {
    path: 'history',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./pages/history/history.module').then(m => m.HistoryPageModule)
  },
  {
    path: 'profile',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule)
  },
  {
    path: 'restaurant',
    loadChildren: () => RestaurantRoutingModule
  },
  {
    path: 'lists',
    loadChildren: () => ListsRoutingModule
  },
  {
    path: 'map-list',
    loadChildren: () => import('./pages/lists/details/map-list/map-list.module').then( m => m.MapListPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

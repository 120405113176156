import { IEnvironment } from './environment-interface';

import pkg from '../../package.json';

export const environment: IEnvironment = {
  version: pkg.version,
  build: pkg.build,
  production: false,
  name: 'development',
  platform: 'local',
  apiUrl: 'http://localhost:8000/',
  pwaUrl: 'http://localhost:8100/',
  sentryApiKey: '14eb449adb8c4127950dca7e843f9ffe7d39e3b4c1234321a72fd2b97f84c8b5',
  enableSentry: false,
  googleApiKey: 'AIzaSyDvbOyPPM9ca8jM6bKbqiINDB1cSTryp6k',
  allowedAuthenticationDomains: [
    'localhost:8000',
  ]
};

<ion-header>
  <ion-toolbar color="primary">
    <ion-fab-button (click)="dismiss()" class="fixInfo right" size="small">
      <ion-icon class="x-large" name="close"></ion-icon>
    </ion-fab-button>
    <ion-thumbnail
      [routerLink]="['/home']"
      routerLinkActive="router-link-active"
      class="logo course-logo"
      style="margin: 0 auto"
    >
      <ion-img src="assets/images/logo.png"></ion-img>
    </ion-thumbnail>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div [innerHtml]="htmlData" class="ion-padding"></div>
</ion-content>

import { environment as defaultEnvironment } from './environment.defaults';
import { IEnvironment } from './environment-interface';

export const environment: IEnvironment = {
  ...defaultEnvironment,
  production: true,
  name: 'production',
  platform: 'netlify',
  apiUrl: 'https://api.courseapp.dev/',
  pwaUrl: 'https://paireapp.io/',
  enableSentry: true,
  allowedAuthenticationDomains: [
    'api.courseapp.dev'
  ]
};

import { Injectable } from '@angular/core';

import { IEnvironment } from '../../environments/environment-interface';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService implements IEnvironment {

  public get version() {
    return environment.version;
  }

  public get build() {
    return environment.build;
  }

  public get production() {
    return environment.production;
  }

  public get name() {
    return environment.name;
  }

  public get platform() {
    return environment.platform;
  }

  public get apiUrl() {
    return environment.apiUrl;
  }

  public get pwaUrl() {
    return environment.pwaUrl;
  }

  public get sentryApiKey() {
    return environment.sentryApiKey;
  }

  public get enableSentry() {
    return environment.enableSentry;
  }

  public get googleApiKey() {
    return environment.googleApiKey;
  }

  public get allowedAuthenticationDomains() {
    return environment.allowedAuthenticationDomains;
  }

  public get displayString() {
    return 'ENVIRONMENT: ' + environment.platform.toUpperCase() + '///' + environment.name.toUpperCase();
  }
}

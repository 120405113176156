import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';

import { JwtHelperService } from '@auth0/angular-jwt';

import { CommonService } from './common.service';
import { ErrorHandlerService } from './error-handler.service';
import { EnvironmentService } from './environment.service';
import { AuthenticationAction } from '../models/authentication-action';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public isRefreshingToken = false;
  public tokenRefreshed = false;
  public tokenVerified = false;

  constructor(
    private http: HttpClient,
    private jwtHelper: JwtHelperService,
    private commonService: CommonService,
    private environmentService: EnvironmentService,
    private errorHandler: ErrorHandlerService
  ) {
  }

  private static get token(): string {
    return localStorage.getItem('token');
  }

  private static set token(value: string) {
    localStorage.setItem('token', value);
  }

  public static get email(): string {
    return localStorage.getItem('email');
  }

  public static set email(value: string) {
    localStorage.setItem('email', value);
  }

  private static get refreshToken(): string {
    return localStorage.getItem('refreshToken');
  }

  private static set refreshToken(value: string) {
    localStorage.setItem('refreshToken', value);
  }

  private static setSession(authenticationAction: AuthenticationAction|any): void {
    if (authenticationAction.access) {
      AuthenticationService.token = authenticationAction.access;
    } else {
      AuthenticationService.token = authenticationAction.access_token;
      AuthenticationService.refreshToken = authenticationAction.refresh_token;
    }
  }

  public get isLoggedIn(): boolean {
    return !this.jwtHelper.isTokenExpired(AuthenticationService.token);
  }

  public get isRefreshTokenPresent(): boolean {
    return !!AuthenticationService.refreshToken;
  }

  public login(email: string, password: string): Observable<AuthenticationAction> {
    return this.http.post<AuthenticationAction>(this.environmentService.apiUrl.concat('auth/login/'),
      { email, password },
      this.commonService.httpOptions
    ).pipe(tap((response: AuthenticationAction) => {
      AuthenticationService.setSession(response);
      this.tokenVerified = true;
    }));
  }

  public logout(): Observable<any> {
    return this.http.post(this.environmentService.apiUrl.concat('auth/logout/'),
      {},
      this.commonService.httpOptions
    );
  }

  public clearTokens() {
    this.tokenVerified = false;
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('token');
  }

  public refreshToken(): void {
    this.isRefreshingToken = true;
    this.http.post(this.environmentService.apiUrl.concat('auth/token/refresh/'),
      { refresh: AuthenticationService.refreshToken },
      this.commonService.httpOptions
    ).subscribe((response: any) => {
      AuthenticationService.setSession(response);
      this.isRefreshingToken = false;
      this.tokenRefreshed = true;
      this.tokenVerified = true;
    }, async error => {
      await this.errorHandler.genericError(error);
      this.isRefreshingToken = false;
      this.tokenVerified = false;
    });
  }

  public verifyToken(): Observable<any> {
    return this.http.post(
      this.environmentService.apiUrl.concat('auth/token/verify/'),
      { token: AuthenticationService.token },
      this.commonService.httpOptions
    );
  }

  public forgotPassword(email: string): Observable<any> {
    return this.http.post(this.environmentService.apiUrl + 'auth/password/reset/',
      { email }
    );
  }

  public changePassword(confirmationCode: string, email: string, password: string): Observable<any> {
    if (confirmationCode.indexOf('@') === -1) {
      return throwError('Invalid confirmation code format');
    }
    const splitConfirmationCode = confirmationCode.replace(' ', '').split('@');
    return this.http.post(this.environmentService.apiUrl + 'auth/password/reset/confirm/',
      {
        uid: splitConfirmationCode[0],
        token: splitConfirmationCode[1],
        email,
        new_password1: password,
        new_password2: password
      },
      this.commonService.httpOptions);
  }

  public register(form: UntypedFormGroup): Observable<any> {
    let dateOfBirth = null;

    if (form.value.dateOfBirth) {
      dateOfBirth = this.commonService.dateTransform(form.value.dateOfBirth);
    }

    return this.http.post(this.environmentService.apiUrl + 'auth/register/',
      {
        name: form.value.name,
        location: form.value.locationSearchQuery || null,
        date_of_birth: dateOfBirth,
        bio: form.value.bio || null,
        email: form.value.email.toLowerCase(),
        password: form.value.password,
      },
      this.commonService.httpOptions);
  }
}

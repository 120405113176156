import { Injector } from '@angular/core';

export class ServiceLocatorService {
  private static injector: Injector;

  public static set Injector(injector: Injector) {
    ServiceLocatorService.injector = injector;
  }

  public static get Injector(): Injector {
    return ServiceLocatorService.injector;
  }
}

import { Component, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.page.html',
  styleUrls: ['./info-modal.page.scss'],
})
export class InfoModalPage {
  @Input() htmlData;

  constructor(
    private modalController: ModalController
  ) {
  }

  public async dismiss() {
    await this.modalController.dismiss();
  }
}

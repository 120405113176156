import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { AuthenticationService } from '../authentication.service';

@Injectable({
  providedIn: 'root'
})
export class LandingGuard implements CanActivate {
  constructor(
      private router: Router,
      private authenticationService: AuthenticationService
  ) { }

  public async canActivate(): Promise<boolean> {
    if (this.authenticationService.isLoggedIn) {
      await this.router.navigate(['/home']);
      return false;
    } else {
      return true;
    }
  }
}

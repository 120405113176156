import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthenticationGuard } from '../../services/auth-guards/authentication.guard';

const routes: Routes = [
  {
    path: 'profile/:id',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./profile/restaurant-profile.module').then(m => m.RestaurantProfilePageModule)
  },
  {
    path: 'rating/:id',
    canActivate: [AuthenticationGuard],
    loadChildren: () => import('./rating/restaurant-rating.module').then(m => m.RestaurantRatingModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RestaurantRoutingModule { }

import { Injectable, NgZone } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { delay, map, retryWhen, tap } from 'rxjs/operators';

import { AuthenticationService } from '../authentication.service';
import { CommonService } from '../common.service';
import { ErrorHandlerService } from '../error-handler.service';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private ngZone: NgZone,
    private authenticationService: AuthenticationService,
    private commonService: CommonService,
    private errorHandler: ErrorHandlerService
  ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap({
        error: async (error: HttpErrorResponse) => {
          if (error.status === 401) {
            if (this.authenticationService.tokenVerified !== null) {
              this.authenticationService.tokenVerified = null;

              this.authenticationService.verifyToken().subscribe({
                next: () => this.authenticationService.tokenVerified = true,
                error: async () => {
                  if (error.status === 401) {
                    if (error.error.detail === 'Authentication credentials were not provided.') {
                      this.authenticationService.refreshToken();
                      await (async () => {
                        while (this.authenticationService.isRefreshingToken) {
                          await new Promise(resolve => setTimeout(resolve, 100));
                        }

                        if (!this.authenticationService.tokenVerified) {
                          await this.commonService.setToast('Credentials expired. Please log in again.');
                          this.authenticationService.clearTokens();
                          this.ngZone.run(() => {
                            this.router.navigate(['']);
                          });
                        }
                      })();
                    } else {
                      await this.commonService.setToast('There was an error with your credentials. ' +
                          'Please log in again.');
                      await this.errorHandler.genericError(error);
                      this.authenticationService.clearTokens();
                      this.ngZone.run(() => {
                        this.router.navigate(['']);
                      });
                    }
                  } else {
                    await this.errorHandler.genericError(error);
                  }
                }
              });
            }
          } else {
            return error;
          }
        }
      }
      ),
      retryWhen((errors: Observable<any>) => {
        let errorCount = 0;

        return errors.pipe(
          delay(1000),
          map((error: any) => {
            if (error.status === 401 && errorCount++ === 3) {
              throw error;
            } else {
              throw error;
            }
          }));
      }));
  }
}

import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private router: Router,
    private zone: NgZone
  ) {
    App.addListener('appUrlOpen', async (event: URLOpenListenerEvent) => {
      await this.zone.run(async () => {
        const passwordResetCode = event.url.split('code=').pop();
        console.debug('Attempting to deep link');
        if (passwordResetCode) {
          console.debug('Resetting password from deep link');
          await this.router.navigate(['/landing'], { queryParams: { 'code': passwordResetCode } });
        } else {
          console.debug('Deep Link POP | ' + event.url.split('.app').pop() + ' | DEEP LINK URL | ' + event.url.split('.app'));
          await this.router.navigate([event.url.split('.app').pop()]);
        }
      });
    });
  }
}

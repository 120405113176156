import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PublicGuard } from '../../services/auth-guards/public.guard';
import { AuthenticationGuard } from '../../services/auth-guards/authentication.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./overview/lists-overview.module').then(m => m.ListsOverviewPageModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 'p/:id',
    loadChildren: () => import('./details/personal/list-details-personal.module').then(m => m.ListDetailsPersonalModule),
    canActivate: [AuthenticationGuard]
  },
  {
    path: 's/:key',
    loadChildren: () => import('./details/shareable/list-details-shareable.module').then(m => m.ListDetailsShareableModule),
    canActivate: [PublicGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ListsRoutingModule { }
